import React from "react"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import SEO from "../components/seo"

import { Link } from "gatsby"
import ConnectSidebar from "../components/layout/connect-sidebar"

import CUSTOMER_SERVICE from "../images/connect/connect-customer-service.png"
import FIND_CENTER from "../images/connect/connect-find-center.png"
//import JOIN_NEWSLETTER from "../images/connect/connect-join-newsletter.png"
import JOIN_EMAIL from "../images/connect/connect-join-email.png"
import REQUEST_CATALOG from "../images/connect/connect-request-catalog.png"
import SCHEDULE_CONSULT from "../images/connect/connect-schedule-consult.png"
import SOCIAL from "../images/connect/connect-social.png"
import VIRTUAL_SHOWROOM from "../images/connect/connect-virtual-showroom.png"

const ConnectPage = () => {
  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Walpole Outdoors - Connect with Us`}
        description={`The standard all other fence and outdoor structures are measured by.`}
      />

      {/* CONTENT GOES HERE */}
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/">Connect with Us</Breadcrumb.Item>
        </Breadcrumb>
        <Row className="generalcontent">
          <Col md={3}>
            <ConnectSidebar></ConnectSidebar>
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Connect with Us</h1>
            <p>Select from the following options:</p>
            <hr />
            <Row className="connect-margin">
              <Col md={4}>
                <div className="connect-container">
                  <Link to="/connect/schedule-consultation" className="childcont">
                    <img src={SCHEDULE_CONSULT} />
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="connect-container">
                  <Link to="/connect/customer-service" className="childcont">
                    <img src={CUSTOMER_SERVICE} />
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="connect-container">
                  <a
                    href="https://www.gallery.walpoleoutdoors.com/"
                    className="childcont"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={VIRTUAL_SHOWROOM} />
                  </a>
                </div>
              </Col>
              <Col md={4}>
                <div className="connect-container">
                  <Link to="/about/our-locations" className="childcont">
                    <img src={FIND_CENTER} />
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="connect-container">
                  <Link to="/connect/newsletter" className="childcont">
                    <img src={JOIN_EMAIL} />
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="connect-container">
                  <Link to="/connect/catalog" className="childcont">
                    <img src={REQUEST_CATALOG} />
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="connect-container">
                  <Link to="/connect/social" className="childcont">
                    <img src={SOCIAL} />
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default ConnectPage
