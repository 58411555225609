import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

const ConnectSidebar = () => (
  <div className="sidebarcontainer">
    <ul className="sidebar-nav">
      <li>
        <Link to="/connect">Connect with Us</Link>
      </li>
      <li>
        <Link to="/connect/schedule-consultation">Schedule a Consultation</Link>
      </li>
      <li>
        <Link to="/connect/customer-service">Contact Customer Service</Link>
      </li>
      <li>
        <Link
          to="https://www.gallery.walpoleoutdoors.com/"
          target="_blank"
          rel="noreferrer"
        >
          Virtual Showroom
        </Link>
      </li>
      <li>
        <Link to="/about/our-locations">Find Your Local Design Center</Link>
      </li>
      <li>
        <Link to="/connect/newsletter">Join Our Email List</Link>
      </li>
      <li>
        <Link to="/connect/catalog">Request a Catalog</Link>
      </li>
      <li>
        <Link to="/connect/social">Connect on Social</Link>
      </li>
    </ul>
  </div>
)
export default ConnectSidebar
